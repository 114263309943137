@use "../../index.scss" as global;

$offblack: #12141750;

$from: global.$primary;
$to: #12141750;

// Media query for mobile
@media only screen and (max-width: 768px) {
    .content{
        padding: 0 1rem !important;
    }
    .Person__wrapper{
        margin: 3rem !important;
    }
}

.About{
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;
    margin: 1rem 0 0 0;
    padding: 9rem 0 6rem 0;
}

.hero{
    color: #ffff;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    padding-top: 1rem;
}

.content{
    align-items: center;
    box-sizing: border-box;
    color: white;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    justify-content: space-around;
    padding: 0 10%;
    width: 100vw;
}

.Person__wrapper{
    background-image: conic-gradient( from -80deg, $from, $from, $from, $to, $to, $from, $to, $to, $to, $from );
    border-radius: 5px;
    flex: 1;
    height: auto;
    margin: 5rem;
    max-width: 300px;
    box-sizing: border-box;
    padding: 2px;
}

.Person{
    align-items: center;
    display: flex;
    flex-flow: column;
    height: auto;
    justify-content: center;
    position: relative;
    background-color: #121417;
    padding: 2rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%230091ff' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");

    .image{
        border-radius: 5px;
        box-sizing: border-box;
        padding: 1rem;
        max-height: 250px;
        width: 110%;
    }

    .name{
        font-size: 1.5rem;
        margin: 2rem 0 0 0;
        text-align: left;
        width: 100%;
    }
    
    .email{
        font-size: 1rem;
        margin: 0.35rem 0;
        text-align: left;
        width: 100%;
    }

    .roles{
        font-size: 1rem;
        text-align: right;
        width: 100%;
        

        .roles__hero{
            font-size: 1.25rem;
            margin: 1rem 0 0 0;
        }
        p{
            margin: 0.25rem;
        }
    }

    &:hover{
        box-shadow: 0 0 5px 1px global.$primary;
        cursor: pointer;
    }

    transition: ease-in-out 250ms;
}


.App {
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.landing{
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100vh;
  justify-content: center;

  .main{
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .action{
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 100vw;
    margin-bottom: 1rem;
  }
}

.hero{
  color: #ffff;
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
}

.chevron{
  color: white;
  height: 32px;
  width: 32px;
  animation: bounce 2s infinite;
  padding: 0.75rem;
  border-radius: 50%;
  &:hover{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@keyframes bounce{
  0%{
    transform: translateY(10px);
    opacity: 0;
  }
  65%{
    transform: translateY(0);
    opacity: 1;
  }
  100%{
    transform: translateY(10px);
    opacity: 0;
  }
}
